.title {
  background: linear-gradient(
    to right,
    white 5%,
    #4caf50 20%,
    #4caf50 80%,
    white 95%
  );
  -webkit-print-color-adjust: exact;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-align: center;
}
