:root {
    @apply font-sans;
    @apply bg-white font-sans;
    --font-family: "Lato", "ui-sans-serif", system-ui;
    @apply text-gray-800;
  }

  
h1 {
  @apply font-bold text-2xl md:text-3xl;
}

h2 {
  @apply font-bold text-xl md:text-2xl;
}

h3 {
  @apply font-bold text-lg md:text-xl;
}

h4 {
  @apply font-bold text-base md:text-lg;
}

h5 {
  @apply text-sm font-bold;
}

h5 {
  @apply font-bold text-gray-500 text-sm md:text-base;
}


.p-button {
  @apply text-sm;
}

.hint,
small {
  @apply text-gray-500 text-sm;
}

// OVERRIDES
.p-tabview-panels {
  @apply p-0;
}
.html5-qrcode-element {
  @apply bg-blue-500 hover:bg-blue-600 p-4 text-white rounded-sm mb-4;
}
.p-submenu-list {
  z-index: 9;
}