.dashboard-household-purok-chart {
  flex: 1;
  display: flex;
  flex-direction: column;
  h5 {
    margin-bottom: 10px;
  }

  .chart-container {
    flex: 1;
    width: 100%;
    position: relative;
    .household-count-overlay {
      position: absolute;
      top: 65%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
      font-weight: bold;
      color: #2d3748;
      line-height: 1.1;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}

.card {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
