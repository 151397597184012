#Login {
  height: 100vh;
  .right-panel {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 768px) {
      background-size: contain;
      background-position: top center;
    }
  }

  .captcha-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1.2rem;
    background-color: #f9f9f9;
    margin-top: 1rem;

    .captcha-text {
      flex-grow: 1;
      text-align: center;
    }

    .refresh-button {
      position: absolute;
      top: 50%;
      right: 0.5rem;
      transform: translateY(-50%);
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      border: none;
      background-color: transparent;
      border-radius: 50%;

      &:hover {
        background-color: #e0e0e0;
      }

      i {
        font-size: 1rem;
      }
    }
  }
}
