.dashboard-voter {
  display: flex;
  flex-direction: column;
  height: 100%;

  .dashboard-voter-chart {
    flex: 1;
    display: flex;
    flex-direction: column;

    h5 {
      margin-bottom: 10px;
    }

    .chart-container {
      flex: 1;
      width: 100%;
      position: relative;
    }
  }

  .card {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
