.dashboard-card-deceased-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      font-size: 24px;
      font-weight: bold;
      color: #2d3748;
      line-height: 1.1;

      @media (max-width: 768px) {
        font-size: 20px;
      }
    }

    h5 {
      font-size: 12px;
      color: #5e5e5e;
      margin-top: 5px;
      font-weight: 600;

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }

  .deceased-icon {
    font-size: 40px;
    background-color: #e0950a;
    padding: 10px;
    border-radius: 50%;
    color: #fff;
    margin-left: 20px;

    @media (max-width: 768px) {
      font-size: 30px;
    }
  }

  @media (max-width: 1200px) {
    padding: 15px;
  }

  @media (max-width: 768px) {
    padding: 10px;
    flex-direction: row;
    align-items: center;
  }
}
.dashboard-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}
