#Certificate {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .certificate-title {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #2c3e50;
  }

  .certificate-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
    width: 100%;
    max-width: calc(100vw - 4rem);
    box-sizing: border-box;

    @media (max-width: 768px) {
      gap: 1rem;
    }

    @media (max-width: 480px) {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      gap: 0.8rem;
    }
  }

  .certificate-card {
    background-color: #ffffff;
    border: 1px solid #7baa86;
    border-radius: 8px;
    padding: 1.5rem;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    text-align: left;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    }

    h2 {
      margin-top: 0;
      font-size: 1.4rem;
      color: #444644;

      @media (max-width: 480px) {
        font-size: 1.2rem;
      }
    }

    p {
      font-size: 1rem;
      color: #555;

      @media (max-width: 480px) {
        font-size: 0.9rem;
      }
    }
  }
}
