.dashboard {
  display: flex;
  flex-direction: column;
  padding: 20px;

  .dashboard-title {
    margin-bottom: 20px;
  }

  .dashboard-card-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    width: 100%;
    justify-items: center;

    .dashboard-card {
      background-color: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 130px;
      text-align: center;
      transition: transform 0.2s, box-shadow 0.2s;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
      }

      @media (max-width: 768px) {
        height: auto;
        padding: 15px;
      }
    }
  }

  .dashboard-chart-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
    width: 100%;

    .dashboard-gender-bar-graph,
    .dashboard-chart-voters,
    .dashboard-chart-age {
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      padding: 20px;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .dashboard-chart-wrapper-household-inhabitant-purok {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;

    .dashboard-chart-inhabitant-purok,
    .dashboard-chart-issue-certificate,
    .dashboard-chart-household-purok {
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      padding: 20px;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
}
