/*
 * Replace this with your own classes
 *
 * e.g.
 * .scan-attendance {
 * }
*/
.add-signature {
  padding: 2em;
  background-color: #F3F4F6;
}
.sigCanvas {
  background-color: white;
}
