.active-sidebar-item {
  background-color: #ffffff;
  border-color: #7baa86;
  color: #7baa86;

  .sidebar-icon {
    color: #7baa86;
  }
}

.hover-sidebar-item:hover {
  background-color: #d7dfd9;
  color: #7baa86;
  .sidebar-icon {
    color: #7baa86;
  }
}
