
@keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeout {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes scalein {
    0% {
      opacity: 0;
      transform: scaleY(0.8);
      transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    }
    100% {
      opacity: 1;
      transform: scaleY(1);
    }
  }
  @keyframes slidedown {
    0% {
      max-height: 0;
    }
    100% {
      max-height: auto;
    }
  }
  @keyframes slideup {
    0% {
      max-height: 1000px;
    }
    100% {
      max-height: 0;
    }
  }
  @keyframes fadeinleft {
    0% {
      opacity: 0;
      transform: translateX(-100%);
      transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }
  @keyframes fadeoutleft {
    0% {
      opacity: 1;
      transform: translateX(0%);
      transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    }
    100% {
      opacity: 0;
      transform: translateX(-100%);
    }
  }
  @keyframes fadeinright {
    0% {
      opacity: 0;
      transform: translateX(100%);
      transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }
  @keyframes fadeoutright {
    0% {
      opacity: 1;
      transform: translateX(0%);
      transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    }
    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }
  @keyframes fadeinup {
    0% {
      opacity: 0;
      transform: translateY(-100%);
      transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  @keyframes fadeoutup {
    0% {
      opacity: 1;
      transform: translateY(0%);
      transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    }
    100% {
      opacity: 0;
      transform: translateY(-100%);
    }
  }
  @keyframes fadeindown {
    0% {
      opacity: 0;
      transform: translateY(100%);
      transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  @keyframes fadeoutdown {
    0% {
      opacity: 1;
      transform: translateY(0%);
      transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    }
    100% {
      opacity: 0;
      transform: translateY(100%);
    }
  }
  @keyframes animate-width {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  @keyframes flip {
    from {
      transform: perspective(2000px) rotateX(-100deg);
    }
    to {
      transform: perspective(2000px) rotateX(0);
    }
  }
  @keyframes flipleft {
    from {
      transform: perspective(2000px) rotateY(-100deg);
      opacity: 0;
    }
    to {
      transform: perspective(2000px) rotateY(0);
      opacity: 1;
    }
  }
  @keyframes flipright {
    from {
      transform: perspective(2000px) rotateY(100deg);
      opacity: 0;
    }
    to {
      transform: perspective(2000px) rotateY(0);
      opacity: 1;
    }
  }
  @keyframes flipup {
    from {
      transform: perspective(2000px) rotateX(-100deg);
      opacity: 0;
    }
    to {
      transform: perspective(2000px) rotateX(0);
      opacity: 1;
    }
  }
  @keyframes zoomin {
    from {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
    50% {
      opacity: 1;
    }
  }
  @keyframes zoomindown {
    from {
      opacity: 0;
      transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    }
    60% {
      opacity: 1;
      transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    }
  }
  @keyframes zoominleft {
    from {
      opacity: 0;
      transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    }
    60% {
      opacity: 1;
      transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    }
  }
  @keyframes zoominright {
    from {
      opacity: 0;
      transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    }
    60% {
      opacity: 1;
      transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    }
  }
  @keyframes zoominup {
    from {
      opacity: 0;
      transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    }
    60% {
      opacity: 1;
      transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    }
  }
  .fadein {
    animation: fadein 0.15s linear;
  }
  
  .fadeout {
    animation: fadeout 0.15s linear;
  }
  
  .slidedown {
    animation: slidedown 0.45s ease-in-out;
  }
  
  .slideup {
    animation: slideup 0.45s cubic-bezier(0, 1, 0, 1);
  }
  
  .scalein {
    animation: scalein 0.15s linear;
  }
  
  .fadeinleft {
    animation: fadeinleft 0.15s linear;
  }
  
  .fadeoutleft {
    animation: fadeoutleft 0.15s linear;
  }
  
  .fadeinright {
    animation: fadeinright 0.15s linear;
  }
  
  .fadeoutright {
    animation: fadeoutright 0.15s linear;
  }
  
  .fadeinup {
    animation: fadeinup 0.15s linear;
  }
  
  .fadeoutup {
    animation: fadeoutup 0.15s linear;
  }
  
  .fadeindown {
    animation: fadeindown 0.15s linear;
  }
  
  .fadeoutdown {
    animation: fadeoutdown 0.15s linear;
  }
  
  .animate-width {
    animation: animate-width 1000ms linear;
  }
  
  .flip {
    backface-visibility: visible;
    animation: flip 0.15s linear;
  }
  
  .flipup {
    backface-visibility: visible;
    animation: flipup 0.15s linear;
  }
  
  .flipleft {
    backface-visibility: visible;
    animation: flipleft 0.15s linear;
  }
  
  .flipright {
    backface-visibility: visible;
    animation: flipright 0.15s linear;
  }
  
  .zoomin {
    animation: zoomin 0.15s linear;
  }
  
  .zoomindown {
    animation: zoomindown 0.15s linear;
  }
  
  .zoominleft {
    animation: zoominleft 0.15s linear;
  }
  
  .zoominright {
    animation: zoominright 0.15s linear;
  }
  
  .zoominup {
    animation: zoominup 0.15s linear;
  }
  
  .animation-duration-100 {
    animation-duration: 100ms !important;
  }
  
  .animation-duration-150 {
    animation-duration: 150ms !important;
  }
  
  .animation-duration-200 {
    animation-duration: 200ms !important;
  }
  
  .animation-duration-300 {
    animation-duration: 300ms !important;
  }
  
  .animation-duration-400 {
    animation-duration: 400ms !important;
  }
  
  .animation-duration-500 {
    animation-duration: 500ms !important;
  }
  
  .animation-duration-1000 {
    animation-duration: 1000ms !important;
  }
  
  .animation-duration-2000 {
    animation-duration: 2000ms !important;
  }
  
  .animation-duration-3000 {
    animation-duration: 3000ms !important;
  }
  
  .animation-delay-100 {
    animation-delay: 100ms !important;
  }
  
  .animation-delay-150 {
    animation-delay: 150ms !important;
  }
  
  .animation-delay-200 {
    animation-delay: 200ms !important;
  }
  
  .animation-delay-300 {
    animation-delay: 300ms !important;
  }
  
  .animation-delay-400 {
    animation-delay: 400ms !important;
  }
  
  .animation-delay-500 {
    animation-delay: 500ms !important;
  }
  
  .animation-delay-1000 {
    animation-delay: 1000ms !important;
  }
  
  .animation-iteration-1 {
    animation-iteration-count: 1 !important;
  }
  
  .animation-iteration-2 {
    animation-iteration-count: 2 !important;
  }
  
  .animation-iteration-infinite {
    animation-iteration-count: infinite !important;
  }
  
  .animation-linear {
    animation-timing-function: linear !important;
  }
  
  .animation-ease-in {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }
  
  .animation-ease-out {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }
  
  .animation-ease-in-out {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
  
  .animation-fill-none {
    animation-fill-mode: none !important;
  }
  
  .animation-fill-forwards {
    animation-fill-mode: forwards !important;
  }
  
  .animation-fill-backwards {
    animation-fill-mode: backwards !important;
  }
  
  .animation-fill-both {
    animation-fill-mode: both !important;
  }