.certification-title {
  display: flex;
  justify-content: center;
}

.letter {
  text-decoration: underline;
  margin-right: 0.5rem;
}

.indented-paragraph {
  text-indent: 0.5in;
  margin: 0;
  text-align: left;
}

@media print {
  @page {
    size: A4 portrait;
  }
}

.content {
  font-size: 13.2px;
}
