.search-results {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  overflow-y: auto;
  max-height: 300px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.search-result-item {
  padding: 10px 15px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #f4f4f4;
  }

  &--selected {
    background-color: #7baa86;
    color: white;
  }

  & + & {
    border-top: 1px solid #e0e0e0;
  }

  .search-result-name {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
