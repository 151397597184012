.custom-font {
  font-family: "Microsoft Himalaya", "Segoe UI", sans-serif;
}

.barangay-official {
  .official {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
}
