#RegisterInhabitantWithAttendance {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .form-section {
    width: 100%;
  }
  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .save-button {
    width: auto;
    padding: 15px;
  }
}
