/*
 * Replace this with your own classes
 *
 * e.g.
 * .print-qr {
 * }
*/
@media print {
    @page {
      size: 2.83in 4.02in;
    }
  }