.dashboard-gender {
  display: flex;
  flex-direction: column;
  height: 100%;

  .dashboard-gender-chart {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;

    h5 {
      margin-bottom: 10px;
    }

    .chart-container {
      flex: 1;
      width: 100%;
      position: relative;
      padding: 20px;
      box-sizing: border-box;
    }
  }

  .card {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
