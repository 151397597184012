.progress-container {
  width: 100%;
  background-color: #dfebe2;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  height: 20px;
  border-radius: 4px;
  transition: width 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-text {
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.not-allowed-row {
  cursor: not-allowed;
  opacity: 0.6;

  &:hover {
    background-color: #f5f5f5;
  }
}
